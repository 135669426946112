import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { AppStore } from "./AppStore";
import { Facebook } from "./Facebook";
import { GooglePlay } from "./GooglePlay";
import { Instagram } from "./Instagram";
import { Pinterest } from "./Pinterest";
import { TikTok } from "./TikTok";
import { Twitter } from "./Twitter";
import { YouTube } from "./YouTube";

interface Props {
  site: string;
  isFrontpageDesign?: boolean;
}

type DownloadLinksType = {
  [key: string]: FC[];
};

export interface SocialLinksComponentType {
  className?: string;
  i13nSubsec?: string;
  style?: React.CSSProperties;
  url: string;
}

type SocialLinksType = {
  [key: string]: {
    component?: FC<SocialLinksComponentType>;
    url: string;
  }[];
};

const socialLinks: SocialLinksType = {
  creators: [
    {
      component: Twitter,
      url: "https://twitter.com/yahoonews",
    },
    {
      component: Facebook,
      url: "https://facebook.com/YahooNews",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahoonews",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/yahoo",
    },
    {
      component: TikTok,
      url: "https://www.tiktok.com/@yahoonews",
    },
  ],
  election: [
    {
      component: Twitter,
      url: "https://twitter.com/yahoonews",
    },
    {
      component: Facebook,
      url: "https://facebook.com/YahooNews",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahoonews",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/yahoo",
    },
    {
      component: TikTok,
      url: "https://www.tiktok.com/@yahoonews",
    },
  ],
  entertainment: [
    {
      component: Twitter,
      url: "https://twitter.com/YahooEnt",
    },
    {
      component: Facebook,
      url: "https://facebook.com/yahooentertainment",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahooentertainment/",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/channel/UCatbb7X8J0TG3OiIY20hpyA",
    },
  ],
  frontpage: [
    {
      component: Twitter,
      url: "https://twitter.com/yahoonews",
    },
    {
      component: Facebook,
      url: "https://facebook.com/YahooNews",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahoonews",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/yahoo",
    },
    {
      component: TikTok,
      url: "https://www.tiktok.com/@yahoonews",
    },
  ],
  lifestyle: [
    {
      component: Twitter,
      url: "https://twitter.com/yahoolife",
    },
    {
      component: Facebook,
      url: "https://facebook.com/yahoolifestyle",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahoolifestyle",
    },
    {
      component: Pinterest,
      url: "https://www.pinterest.com/yahoolifestyle/",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/channel/UCNM2ti9d-f8z_Hqmt9cB0Iw",
    },
  ],
  news: [
    {
      component: Twitter,
      url: "https://twitter.com/yahoonews",
    },
    {
      component: Facebook,
      url: "https://facebook.com/YahooNews",
    },
    {
      component: Instagram,
      url: "https://instagram.com/yahoonews",
    },
    {
      component: YouTube,
      url: "https://www.youtube.com/yahoo",
    },
    {
      component: TikTok,
      url: "https://www.tiktok.com/@yahoonews",
    },
  ],
};

const downloadLinks: DownloadLinksType = {
  news: [AppStore, GooglePlay],
};

export const SocialLinks: FC<Props> = ({ isFrontpageDesign = false, site }) => {
  const intl = useIntl();

  const downloadLinksForSite = !isFrontpageDesign && downloadLinks[site];
  const socialLinksForSite = socialLinks[site];
  if (!downloadLinksForSite && !socialLinksForSite) {
    return null;
  }

  return (
    <div
      className={classNames(
        "shrink-0 grow-0 md:ml-auto md:w-full md:basis-72",
        !isFrontpageDesign && "w-80",
        isFrontpageDesign && "mt-2",
      )}
    >
      {socialLinksForSite && (
        <div
          className={classNames(
            !isFrontpageDesign && "mb-6",
            isFrontpageDesign && "mb-1",
          )}
        >
          {!isFrontpageDesign && (
            <div className="hidden text-sm leading-9 text-battleship md:block">
              {intl.formatMessage({ id: "footer.SOCIAL" })}
            </div>
          )}

          <ul
            className={classNames(
              "mt-5 flex list-none md:mt-1",
              isFrontpageDesign && "justify-center",
            )}
          >
            {socialLinksForSite.map((link, index) => {
              const { component: Component, url } = link;
              if (Component) {
                return (
                  <li className="mr-8 last:mr-0" key={index}>
                    <Component url={url} />
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      )}
      {downloadLinksForSite && (
        <div className="hidden md:block">
          <div className="text-sm leading-9 text-battleship">
            {intl.formatMessage({ id: "footer.DOWNLOAD" })}
          </div>
          <ul className="flex h-10 justify-between">
            {downloadLinksForSite.map((Component, index) => (
              <li key={index}>
                <Component />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
