import { I13nAnchor } from "@yahoo-creators/i13n";
import { Fragment, type FC } from "react";
import {
  isCompoundPrivacyLink,
  parseCompoundPrivacyLink,
  type CompoundPrivacyLink,
  type PrivacyLink as PrivacyLinkType,
} from "../lib/privacy";
import { type articleFooterDataYlk } from "./ArticleFooter";

const CompoundPrivacyLink: FC<{
  link: CompoundPrivacyLink;
  dataYlk: articleFooterDataYlk;
}> = ({ link, dataYlk }) => {
  return (
    <>
      {parseCompoundPrivacyLink(link).map((item, index) => {
        if (item.type === "link") {
          return (
            <I13nAnchor
              dataYlk={dataYlk}
              className="text-dolphin"
              href={item.url}
              key={index}
            >
              {item.text}
            </I13nAnchor>
          );
        }

        return <Fragment key={index}>{item.text}</Fragment>;
      })}
    </>
  );
};

export const ArticlePrivacyLink: FC<{
  link: PrivacyLinkType;
  dataYlk: articleFooterDataYlk;
}> = ({ link, dataYlk }) => {
  if (isCompoundPrivacyLink(link)) {
    return <CompoundPrivacyLink link={link} dataYlk={dataYlk} />;
  } else {
    return (
      <I13nAnchor dataYlk={dataYlk} className="text-dolphin" href={link.url}>
        {link.label}
      </I13nAnchor>
    );
  }
};
