import { type FC, type ReactNode } from "react";

interface Props {
  children: ReactNode;
  href: string;
}

export const FooterLink: FC<Props> = ({ children, href }) => {
  return (
    // TODO: fix
    // eslint-disable-next-line react/forbid-elements
    <a
      className="relative block overflow-hidden text-xs leading-9 text-black no-underline after:bottom-[3px] after:left-0 after:h-[2px] after:w-full after:translate-x-[-101%] after:bg-black after:duration-300 after:ease-in-out after:content-['_'] after:hover:translate-x-0 md:text-[13px] md:leading-9 md:after:absolute"
      href={href}
    >
      {children}
    </a>
  );
};
