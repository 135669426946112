"use client";

import Image from "next/image";
import { type FC } from "react";
import {
  isCompoundPrivacyLink,
  isIconPrivacyLink,
  parseCompoundPrivacyLink,
  type CompoundPrivacyLink,
  type IconPrivacyLink,
  type PrivacyLink as PrivacyLinkType,
} from "../lib/privacy";
import { FooterLink } from "./FooterLink";

const Icon = ({ icon }: Pick<IconPrivacyLink, "icon">) => {
  return (
    <Image
      alt=""
      aria-hidden
      className="ml-px inline-block h-[13px] w-[25px]"
      height={13}
      src={icon}
      width={25}
      role="img"
    />
  );
};

const CompoundPrivacyLink: FC<{ link: CompoundPrivacyLink }> = ({ link }) => {
  return (
    <div className="inline-flex">
      {parseCompoundPrivacyLink(link).map((item, index) => {
        if (item.type === "link") {
          return (
            <FooterLink href={item.url} key={index}>
              {item.text}
            </FooterLink>
          );
        }

        return (
          <span
            className="whitespace-pre-wrap text-xs leading-9 text-black md:text-sm md:leading-9"
            key={index}
          >
            {item.text}
          </span>
        );
      })}
    </div>
  );
};

export const PrivacyLink: FC<{
  link: PrivacyLinkType;
}> = ({ link }) => {
  if (isCompoundPrivacyLink(link)) {
    return <CompoundPrivacyLink link={link} />;
  }

  return (
    <FooterLink href={link.url}>
      {link.label}
      {isIconPrivacyLink(link) && <Icon icon={link.icon} />}
    </FooterLink>
  );
};
