"use client";

import { I13nAnchor } from "@yahoo-creators/i13n";
import Image from "next/image";
import { type FC } from "react";
import {
  isCompoundPrivacyLink,
  isIconPrivacyLink,
  parseCompoundPrivacyLink,
  type CompoundPrivacyLink,
  type IconPrivacyLink,
  type PrivacyLink as PrivacyLinkType,
} from "../lib/privacy";

const Icon = ({ icon }: Pick<IconPrivacyLink, "icon">) => {
  return (
    <Image
      alt=""
      aria-hidden
      className="ml-px inline-block h-[13px] w-[25px]"
      height={13}
      src={icon}
      width={25}
    />
  );
};

const CompoundPrivacyLink: FC<{ link: CompoundPrivacyLink }> = ({ link }) => {
  return parseCompoundPrivacyLink(link).map((item, index) => {
    if (item.type === "link") {
      return (
        <I13nAnchor
          className="text-dolphin hover:text-dory"
          href={item.url}
          dataYlk={{ ylk: "sec:footer;subsec:corp" }}
          key={index}
        >
          {item.text}
        </I13nAnchor>
      );
    }

    return (
      <span className="whitespace-pre-wrap leading-5" key={index}>
        {item.text}
      </span>
    );
  });
};

export const HomepagePrivacyLink: FC<{
  link: PrivacyLinkType;
}> = ({ link }) => {
  if (isCompoundPrivacyLink(link)) {
    return <CompoundPrivacyLink link={link} />;
  }

  return (
    <I13nAnchor
      className="text-dolphin hover:text-dory"
      href={link.url}
      dataYlk={{ ylk: "sec:footer;subsec:corp" }}
    >
      {link.label}
      {isIconPrivacyLink(link) && <Icon icon={link.icon} />}
    </I13nAnchor>
  );
};
