import { I13nAnchor } from "@yahoo-creators/i13n";
import Image from "next/image";
import { useIntl } from "react-intl";

export const AppStore = () => {
  const intl = useIntl();
  return (
    <I13nAnchor
      dataYlk={{ elm: "download", itc: 0, sec: "footer", slk: "appStore" }}
      href="https://app.appsflyer.com/id304158842?pid=inproduct&c=US_Acquisition_YMktg_Desktopfooter__&af_sub1=Acquisition&af_sub2=US_YMktg&af_sub3=&af_sub4=&af_sub5=Desktopfooter__Static_"
      rel="noreferrer noopener"
      target="_blank"
    >
      <Image
        className="h-full max-w-36"
        alt={intl.formatMessage({ id: "footer.APP_STORE" })}
        height={40}
        priority={false}
        src="https://s.yimg.com/cv/apiv2/default/20190724/app-store-icon@3x.png"
        width={135}
      />
    </I13nAnchor>
  );
};
