"use client";

import { type PrivacyJurisdiction } from "@vzmi/privacy-jurisdiction-sdk";
import { type ValueOf } from "type-fest";

type PrivacyLinkMap = ReturnType<
  PrivacyJurisdiction["getPrivacyLinksV2"]
>["links"];

export type PrivacyLinkKey = keyof PrivacyLinkMap;
export type PrivacyLink = ValueOf<PrivacyLinkMap>;
export type PrivacyEntry = [PrivacyLinkKey, PrivacyLink];

export type CompoundPrivacyLink = Extract<PrivacyLink, { multiurl: boolean }>;
export type SingularPrivacyLink = Exclude<PrivacyLink, CompoundPrivacyLink>;
export type IconPrivacyLink = Extract<SingularPrivacyLink, { icon: string }>;

export type CompoundPrivacyLinkItem =
  | {
      text: string;
      type: "link";
      url: string;
    }
  | {
      text: string;
      type: "text";
    };

type Region = ReturnType<typeof getRegion>;
/**
 * Uses the Privacy Jurisdiction SDK to retrieve the applicable privacy links,
 * in display order, for the current site and visitor
 */
export const getPrivacyLinkEntries = async ({
  acookieFields,
  lang,
  partner,
  region,
}: {
  acookieFields?: string;
  lang: string;
  partner: string;
  region: Region;
}): Promise<[PrivacyLinkKey, PrivacyLink][]> => {
  const { PrivacyJurisdiction } = await import(
    "@vzmi/privacy-jurisdiction-sdk"
  ).then((mod) => mod.default);

  const individualPolicyLinks = false;
  const privacy = new PrivacyJurisdiction();
  const response = privacy.getPrivacyLinksV2(
    privacy.NAMESPACE_YAHOO,
    partner,
    lang,
    { "x-acookie-fields": acookieFields, "x-jurisdiction-type": region },
    individualPolicyLinks,
  );
  if (!response) {
    throw new Error("No response received from the Privacy Jurisdiction SDK");
  }
  // https://git.ouryahoo.com/gdpr/Privacy-Jurisdiction-Node-SDK/pull/51
  const footerSequence = response.footerSequence as PrivacyLinkKey[];

  return footerSequence.reduce(
    (links, key) => {
      const link = response.links[key];
      if (!link) {
        return links;
      }

      return [...links, [key, link]];
    },
    [] as [PrivacyLinkKey, PrivacyLink][],
  );
};

export const getRegion = () => {
  const { isEU, jurisdiction, state } = window.YahooCJS.getJurisdictionInfo();

  if (isEU) {
    return "GDPR"; // Europe region
  } else if (state === "CA") {
    return "CCPA"; // California
  } else if (jurisdiction === "US") {
    return "US"; // United States (not including California)
  } else {
    return "WORLD"; // Non-european non-US country
  }
};

export const isCompoundPrivacyLink = (
  link: PrivacyLink,
): link is CompoundPrivacyLink => "multiurl" in link;

export const isIconPrivacyLink = (link: PrivacyLink): link is IconPrivacyLink =>
  "icon" in link;

export const parseCompoundPrivacyLink = (
  link: CompoundPrivacyLink,
): CompoundPrivacyLinkItem[] => {
  const tokens = link.label
    .split(/\${([\w_]+)}(.+?)\${end_link}/g)
    .filter(Boolean);

  const isUrlToken = (token: string): token is keyof typeof link.urls =>
    token in link.urls;

  const items: CompoundPrivacyLinkItem[] = [];
  for (let index = 0; index < tokens.length; index++) {
    const token = tokens[index];
    if (isUrlToken(token)) {
      // get the link's text from the following token
      index++;
      const text = tokens[index];

      items.push({
        text,
        type: "link",
        url: link.urls[token],
      });
    } else {
      items.push({ text: token, type: "text" });
    }
  }

  return items;
};
