"use client";

import Script from "next/script";
import { useId } from "react";
import {
  getPrivacyLinkEntries,
  getRegion,
  type PrivacyEntry,
} from "../lib/privacy";

export const ConsentLibrary = ({
  acookieFields,
  lang,
  onLoadPrivacyEntries,
  partner,
}: {
  acookieFields?: string;
  lang: string;
  onLoadPrivacyEntries: (entries: PrivacyEntry[]) => void;
  partner: string;
}) => {
  return (
    <Script
      // If you don't do this, the `onLoad` callback will only work for the first caller of the script. In the case of the
      // footer, it will only work for the first instance of the footer links (ie the first article footer) and not called
      // for the subsequent instances of the footer links including those at the bottom of the page. TODO: fix all scripts
      //
      // Warning: An id property must be assigned for inline scripts in order for Next.js to track and optimize the script
      // https://nextjs.org/docs/app/building-your-application/optimizing/scripts#inline-scripts
      id={useId()}
      onLoad={() => {
        const region = getRegion();
        getPrivacyLinkEntries({ acookieFields, lang, partner, region })
          .then((entries) => onLoadPrivacyEntries(entries))
          .catch(() => onLoadPrivacyEntries([]));
      }}
      src="https://s.yimg.com/oa/consent.js"
      strategy="lazyOnload"
    />
  );
};
