"use client";

import { I13nAnchor, useRapid } from "@yahoo-creators/i13n";
import { useState, type FC } from "react";
import { useIntl } from "react-intl";
import { type PrivacyEntry } from "../lib/privacy";
import { ConsentLibrary } from "./ConsentLibrary";
import { HomepagePrivacyLink } from "./HomepagePrivacyLink";
import { SocialLinks } from "./SocialLinks";

interface Props {
  acookieFields?: string;
  className?: string;
  lang: string;
  partner: string;
  site: string;
  links: {
    category?: { text: string; url: string }[];
    corp?: { text: string; url: string }[];
    homepage?: { text: string; url: string }[];
  };
}

const dataYlk = {
  elm: "corp",
  itc: 0,
};

export const HomepageFooter: FC<Props> = ({
  acookieFields,
  className,
  lang,
  partner,
  site = "creators",
  links,
}) => {
  useRapid("footer");
  const intl = useIntl();
  const [privacyEntries, setPrivacyEntries] = useState<PrivacyEntry[]>([]);
  return (
    <div
      id="footer"
      className={
        className || "text-center lg:sticky lg:top-[720px] xl:max-w-[300px]"
      }
    >
      <SocialLinks site={site} isFrontpageDesign={true} />
      <ul className="inline-flex flex-wrap items-center justify-center gap-1 text-xs leading-3 md:mb-0 md:text-[13px]">
        {privacyEntries.map(([key, link]) => (
          <li className="shrink-0 text-dolphin" key={key}>
            <HomepagePrivacyLink link={link} />
          </li>
        ))}
        {links?.homepage &&
          links?.homepage.map((link, index) => (
            <li className="shrink-0 text-dolphin" key={index}>
              <I13nAnchor
                dataYlk={dataYlk}
                href={link.url}
                className="text-dolphin hover:text-dory"
              >
                {link.text}
              </I13nAnchor>
            </li>
          ))}
      </ul>
      <small className="mt-1 block text-xs text-dolphin md:text-[13px]">
        {intl.formatMessage(
          { id: "footer.COPYRIGHT" },
          { year: new Date().getFullYear() },
        )}
      </small>
      <ConsentLibrary
        acookieFields={acookieFields}
        lang={lang}
        onLoadPrivacyEntries={setPrivacyEntries}
        partner={partner}
      />
    </div>
  );
};
